/*********************
*** Page: About ***
*********************/

.about {
    .row-title {
        padding: 4rem 1rem;
    
        @include media-breakpoint-up-xl {
            padding: 5rem 1rem;
        }
    }

    .highlighted-title {
        color: $secondary-color;
    }

    h2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h3 {
        font-family: $secondary-font-family;

        @include media-breakpoint-up-md {
            font-size: 3rem;
        }
    }
}

.bg-blue-transparent {
    background: $secondary-color;
    background: linear-gradient(to right,  $secondary-color 0%, $secondary-color 90%,transparent 90%,transparent 100%); /* W3C */
    height: 200px;
    position: absolute;
    
    @include media-breakpoint-up-md {
        height: 400px;
    }
}

.bg-transparent-dimcream {
    background: linear-gradient(transparent 0%, transparent 30%, $dimcream-color 30%, $dimcream-color 100%);
    @include media-breakpoint-up-lg {
        background: linear-gradient(transparent 0%, transparent 50%, $dimcream-color 50%, $dimcream-color 100%);
    }
}

.team-member {
    padding: 2rem 1rem 0;

    @include media-breakpoint-up-md {
        padding-bottom: 2rem;
    }

    @include media-breakpoint-up-lg {
        &:nth-child(odd) {
            padding-top: 6rem;
        }
    }

    img {
        padding-bottom: 1rem;
    }
}