/*********************
*** Page: Career ***
*********************/

.career {
    .page-title {
        @include media-breakpoint-down-md {
            font-size: 3rem;
        }
    }

    .highlighted-title {
        color: $mustard-color;
    }

    .apply-button {
        background-color: $mustard-color;
        padding: 0.7rem 2rem;
        border-radius: 2rem;
        color: $white-color;
        font-family: $primary-font-family;
        font-weight: bold;
        transition-duration: 0.5s;

        &:hover {
            background-color: $secondary-color;
        }
    }

    form {
        margin: 4rem auto 2rem;

        input {
   
            &::placeholder {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        select {
            color: $dark-color;
            font-weight: bold;
            -webkit-appearance: none;

            &:invalid {
                color: $white-color;
                font-weight: normal;
            }
        }
        
        .select-style {
            position: relative;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 1px;
                background: $white-color;
            }

            &::before {
                right: 20px;
                transform: rotate(45deg);
            }

            &::after {
                right: 13px;
                transform: rotate(-45deg);
            }
        }
        
        select option {
            background: $mustard-color;
            color: $white-color;
        }

        .form-control, .form-check-label, .custom-file-label {
            font-size: 0.8rem;

            @include media-breakpoint-up-lg {
                font-size: 1rem;
            }
        }

        .custom-file-label {
            display: block;
            background: $mustard-color;
            text-align: center;
            position: relative;
            margin: 2rem auto;
            color: white;
            font-weight: 600;
            border: none;
            border-radius: 20rem;
            cursor: pointer;

            &:hover {
                background: $teal-color;
            }

            &::after {
                display: none;
            }
        }

        .custom-file-input {
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: -1;
        }

        a {
            color: $white-color;

            &:hover {
                color: $mustard-color;
            }
        }
    }
}

.bg-mustard-transparent {
    background: $mustard-color;
    background: linear-gradient(to right,  $mustard-color 0%, $mustard-color 90%,transparent 90%,transparent 100%); /* W3C */
    height: 400px;
    position: relative;
}

.career-wrapper {
    position: relative;
    overflow: hidden;
}

.career-openings::before {
    content: '';
    display: block;
    position: absolute;
    left: 5%;
    top: 5rem;
    height: 100%;
    border-left: 1px solid $dark-color;
    z-index: 1;
}

.career-openings-text {
    position: absolute;
    left: 8%;
    top: 4rem;
    color: $dark-color;
    transform: rotate(90deg);
    transform-origin: left;

    @include media-breakpoint-up-lg {
        left: 6%;
    }
}

#accordionCareer {
    margin: 6rem auto 8rem;
}

.career-card {
    margin: 3rem auto;
}

.opening-title {
    button {
        &:focus {
            outline: none;
        }
    }
}

.btn-opening {
    border: none;
    background-color: transparent;
    color: $mustard-color;
    transition-duration: 0.3s;
    text-align: left;
}

.active .btn-opening {
    color: $dark-color;
    transition-duration: 0.3s;
}

.career-form {
    background: linear-gradient(to bottom,  $mustard-color 0%, $mustard-color 4%, $yellow-color 4%, $yellow-color 5%, $secondary-color 5%, $secondary-color 100%);
    color: $white-color;
    padding: 4rem 1rem;

    @include media-breakpoint-up-lg {
        padding: 8rem 6.5rem ;
    }
    
    h3 {
        font-family: $secondary-font-family;

        @include media-breakpoint-up-md {
            font-size: 3rem;
        }
    }
}