/*********************
*** Page: Am8zing ***
*********************/

#work-am8zing-header {

    #am8zing-header-img {
        margin-top: -15%;

        @include media-breakpoint-up-md {
            margin-top: -5%;
        }

        .col-md {
            padding-right: 5px;
            padding-left: 5px;
        }

        #am8zing-celeb-logo {
            margin-top: 5%;

            @include media-breakpoint-up-lg {
                width: 45%;
            }
        }
    }
}

.bg-tiffany-line {
    background: #8FC6BA;
    height: 84%;
    position: absolute;
    top: 25%;
    // z-index: -1;
}

#pattern-header-green {
    left: 0;
    bottom: 18%;
    width: 19%;
    
    @include media-breakpoint-up-lg {
        bottom: 10%;
    }
}

#pattern-header-white {
    left: 2%;
    bottom: 20%;
    width: 17%;
}

#pattern-header-blue {
    right: 0;
    bottom: -20%;
    width: 18%;
    
    @include media-breakpoint-up-lg {
        bottom: -30%;
    }
}

#work-am8zing-content {
    @include media-breakpoint-up-lg {
        padding-right: 6rem;
    }

    @include media-breakpoint-up-xxl {
        padding-right: 12rem;
    }

    #counter {
        padding-top: 0;
    }
}

.main-img {
    position: relative;
    z-index: 1;
}

#phone-pattern-green {
    width: 55%;
    bottom: 10%;
    left: 15%;
}

#phone-pattern-blue {
    width: 45%;
    bottom: -2%;
    right: 16%;
}

#laptop-pattern-blue {
    width: 60%;
    top: 8%;
    left: -10%;
}

#laptop-pattern-line {
    width: 80%;
    top: 22%;
    left: -20%;
}