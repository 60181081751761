/**********************
*** Page: Thank You ***
*** Page: PDPA ***
*** Page: Disclaimer ***
**********************/

.thankYou, .pdpa, .disclaimer {
    .middle-content {
        padding: 1rem;
        min-height: calc(100vh - 220px);

        @include media-breakpoint-up-lg {
            width: auto;
        }
    }

    a {
        font-family: $primary-font-family;
        font-weight: bold;
    }

    ~ footer {
        @include media-breakpoint-only-md {
            padding: 0;
        }
    }
}