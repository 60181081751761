/*****************
*** Navigation ***
*****************/

.top-bar {
  padding: 0.5rem 0;
  margin-left: -1rem;

  @include media-breakpoint-up-xl {
      padding: 1rem;
  }
}

.navbar-brand {
  @include media-breakpoint-up-md {
    margin-left: 2rem;
  }
}

button.navbar-toggler {
    background-color: $primary-color;
    border-radius: 50%;
    outline: none;
    width: 2.5em;
    height: 2.5em;
    position: relative;
}

span.menu-hamburger {
    width: 100%;
    height: 2px;
    background-color: $white-color;
    display: block;
    position: relative;
    content: '';

    &.active {
      background-color: transparent;

      &::before {
        transform: rotate(45deg);
        top: unset;
        height: 4px;
      }
      
      &::after {
        transform: rotate(-45deg);
        bottom: unset;
        height: 4px;
      }
    }

    &:before {
        width: 24px;
        height: 2px;
        background-color: $white-color;
        display: block;
        position: absolute;
        top: 0.4em;
        content: '';
    }
    &:after {
        width: 24px;
        height: 2px;
        background-color: $white-color;
        display: block;
        position: absolute;
        bottom: 0.4em;
        content: '';
    } 
}

#modalNavigation {

  &.show {
    padding: 0% !important;
    background: linear-gradient(to right, #E78F00 0%, #E78F00 51.4%, #E6A629 51.4%, #E6A629 52.85%, #FDC710 52.85%, #FDC710 53.35%, #6D8F95 53.35%, #6D8F95 100%);
  }

  .modal-full {
    min-width: 100%;
    margin: 0;
  }
  
  .modal-content {
    min-height: 100vh;
    border: 1px solid transparent; 
    border-radius: unset;
    background: $bg-two-colors-borders;
    color: $white-color;
    padding: 1rem;

    @include media-breakpoint-up-md {
      background: $bg-two-colors-borders-desktop;
      padding: 1.2rem;
    }

    @include media-breakpoint-up-lg {
      padding: 2rem 4rem;
    }
  }

  .modal-header {
    border-bottom: none;
    position: relative;
    padding: 0;

    .close {
      outline: none;
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }

  .nav-wrapper {
    @include media-breakpoint-up-md {
      height: 78vh;
      align-items: center;
    }
  }

  .modal-body {
    padding: 1rem;
    
    .nav-link {
      font-size: 1.5rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $white-color;
      padding: 0;

      @include media-breakpoint-up-md {
        font-size: 2rem;
      }

      @include media-breakpoint-up-lg {
        font-size: 2.5rem;
      }

      @include media-breakpoint-up-xxl {
        font-size: 3.5rem;
      }
      &.active {
        color: $yellow-color;
    
        &::after {
          content: '.';
        }
      }
    }
    
    .nav-item {
      
      &.active {
        a {
          color: $yellow-color;
      
          &::after {
            content: '.';
          }
        }
      }
      
    }
  }
}

.footer-disclaimer {
  padding-top: 2rem;
  
  @include media-breakpoint-up-md {
    padding-left: 0;
    left: 63%;
    font-size: 1rem;
  }

  @include media-breakpoint-up-lg {
    padding-left: 2px;
  }
}

.show-short-window {
  @media screen and (min-height: 741px) {
      display:none;
  }
}

.show-high-window {
  .footer-disclaimer {
    position: absolute;
    bottom: 0;
  }

  @media screen and (max-height: 740px) {
      display:none;
  }
}

.nav-left-col {
  display: flex;
  align-items: center;

  @include media-breakpoint-down-sm {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }

  @include media-breakpoint-up-xl {
    padding-right: 16%;
  }
}

.contact-info {
  padding-top: 2rem;
  padding-bottom: 2rem;
  
  @include media-breakpoint-up-md {
    padding: 2rem;
  }
  
  @include media-breakpoint-up-xl {
    padding: 4rem;
  }

  h4 {
    font-family: $secondary-font-family;
    font-weight: 500;
  }

  hr {
    border-color: $white-color;
    margin: 2rem 0;
  }
  
  p {
    font-size: small;
    margin-bottom: 0.2rem;
    
    @include media-breakpoint-up-xl {
      font-size: 1rem;
    }

  a {
    line-height: 2;

    &:hover {
      color: $mustard-color;
    }
  }
}

  .contact-info-highlight {
    font-family: $secondary-font-family;
    font-size: 1rem;
    color: $white-color;

    small {
      font-size: 50%;
    }

    @include media-breakpoint-up-lg {
      font-size: 1.2rem;
    }
  }

  .social-icon {
    width: 38px;
    height: auto;
  }
}

#toTop {
  display: none;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  padding: 8px 8px 10px;
  border-radius: 50%;
  border: 2px solid $white-color;

  @include media-breakpoint-up-md {
    bottom: 80px;
    right: 30px;
  }
}

#toTop:hover {
  background-color: $secondary-color;
}

@keyframes navSlideIn {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes navSlideOut {
  from {
    transform: none;
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.target-element {
  .element-animation {
    animation-duration: 0.6s;
    transition-timing-function: ease;
    animation-fill-mode: both;
  }
  .navslidein {
    animation-name: slideInRight;
  }
  .navslideout {
    animation-name: navSlideOut;
  }
}

.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog {
  transform: translate(0);
  transition: unset;
}

.brands {
  font-family: $primary-font-family;
  color: $white-color;
}