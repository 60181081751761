/*********************
*** Page: Momentum ***
*********************/

main.momentum .content-container {
	background-color: $light-grey-color;
}

main.momentum #momentum-phone-mockup {
	@include media-breakpoint-down-md {
		display: block;
		width: 60%;
		margin: auto;
		margin-top: 3rem;
	}

	@media only screen and (min-width: 1900px) {
		display: block;
		width: 80%;
		margin: auto;
	}
}
