/*  =========
    Load Font
    =========
*/


// Butler
@font-face {
    font-family: 'Butler';
    src: url('../fonts/Butler-Medium.woff2') format('woff2'),
        url('../fonts/Butler-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

// DIN-Regular
@font-face {
    font-family: 'DIN';
    src: url('../fonts/DIN-Regular.woff2') format('woff2'),
        url('../fonts/DIN-Regular.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

// Open Sans
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');