/************************
*** Website Variables ***
************************/

$primary-font-family: 'DIN', Arial, Helvetica, sans-serif;
$secondary-font-family: 'Butler', "Times New Roman", Times, serif;
$tertiary-font-family: 'Open Sans', Arial, Helvetica, sans-serif;

/* Colors in Coming Soon Page */
$mustard-color: #E6A629;
$yellow-color: #FDC710;
$teal-color: #B7D6D8;
$red-color: #D65E61;
$dimcream-color: #F0E7DC;
$white-color: #ffffff;
$light-grey-color: #f5f5f5;
$grey-color: #E4E4E4;
$dark-color: #303030;
$theme-dark-color: #60605e;

/* Colors in whole website */
$primary-color: #E78F00;
$secondary-color: #6D8F95;

/* Background two colors */
$bg-two-colors: linear-gradient(to right,  $primary-color 0%, $primary-color 80%, $secondary-color 80%, $secondary-color 100%);
$bg-two-colors-desktop: linear-gradient(to right,  $primary-color 0%, $primary-color 52%, $secondary-color 52%, $secondary-color 100%);

/* Background two colors with borders*/
$bg-two-colors-borders: linear-gradient(to bottom,  $primary-color 0%, $primary-color 44%, $mustard-color 44%, $mustard-color 45.5%, $yellow-color 45.5%, $yellow-color 46%, $secondary-color 46%, $secondary-color 100%);
$bg-two-colors-borders-longer: linear-gradient(to bottom,  $primary-color 0%, $primary-color 58%, $mustard-color 58%, $mustard-color 59.5%, $yellow-color 59.5%, $yellow-color 60%, $secondary-color 60%, $secondary-color 100%);
$bg-two-colors-borders-tablet: linear-gradient(to bottom,  $primary-color 0%, $primary-color 57%, $mustard-color 57%, $mustard-color 58.5%, $yellow-color 58.5%, $yellow-color 59%, $secondary-color 59%, $secondary-color 100%);
$bg-two-colors-borders-desktop: linear-gradient(to right,  $primary-color 0%, $primary-color 52%, $mustard-color 52%, $mustard-color 53.5%, $yellow-color 53.5%, $yellow-color 54%, $secondary-color 54%, $secondary-color 100%);
