/*********************
*** Page: Ferrari ***
*********************/

#work-ferrari-header {

    #ferrari-header-img {
        margin-top: -15%;

        @include media-breakpoint-up-md {
            margin-top: -5%;
        }

        .col-md {
            padding-right: 5px;
            padding-left: 5px;
        }

        #ferrari-anniv-logo {
            max-width: 100%;
            margin-top: 5%;

            @include media-breakpoint-up-lg {
                max-width: 100%;
            }
        }
    }
}

.bg-darkred-line {
    background: #B2080F;
    height: 5%;
    position: absolute;
    top: 26%;
    // z-index: -1;
}

.bg-brightred-line {
    background: #E10512;
    height: 84%;
    position: absolute;
    top: 30%;
    // z-index: -1;
}

.work-content-smaller {
    @include media-breakpoint-up-md {
        &.work-content {
            padding-bottom: 0;
            padding-top: 4rem;
        }
    }
    
    #counter {
        padding-bottom: 0;
    }
}

#ferrari-vid-left {
    padding-left: 5%;
    margin-top: 5%;
    
    @include media-breakpoint-up-lg {
        margin-top: -10%;
    }

    div {
        div {
            padding:100% 0 0 0;
            position:relative;

            @include media-breakpoint-up-lg {
                padding:121% 0 0 0;
            }
        }
    }
}

#ferrari-vid-right {
    padding-right: 5%;
    margin-top: 5%;
    
    @include media-breakpoint-up-lg {
        margin-bottom: -10%;
        margin-top: 0%;
    }

    div {
        div {
            padding:100% 0 0 0;
            position:relative;

            @include media-breakpoint-up-lg {
                padding:121% 0 0 0;
            }
        }
    }
}