/*********************
*** Page: EW Labs ***
*********************/

#work-ewlabs-header {

    #ewlabs-header-img {
        width: 36%;
        margin-top: -7%;
        margin-left: 10%;
        position: relative;

        @include media-breakpoint-up-lg {
            margin-left: -5%;
        }
    }
}

.bg-cream-line {
    background: #FBF6EC;
    height: 400px;
    position: absolute;
    top: 9%;
}

#work-ewlabs-content {
    padding-bottom: 6rem;

    #counter {
        padding-bottom: 0;
    }
}

#ewlabs-side-img {
    width: 36%;
    margin-left: 55%;
    margin-top: -17%;
    position: relative;

    @include media-breakpoint-up-lg {
        margin-left: 30%;
    }
}

.circle {
    border-radius: 50%;
    background-color: rgba(252, 89, 89, 0.3);
    position: absolute;
}

#circle-left {
    width: 15vw;
    height: 15vw;
    top: 11%;
    left: 30%;

    @include media-breakpoint-up-lg {
        width: 15vh;
        height: 15vh;
    }
}

#circle-right-top {
    width: 35vw;
    height: 35vw;
    top: 0%;
    left: unset;
    right: 8%;

    @include media-breakpoint-up-lg {
        width: 35vh;
        height: 35vh;
    }
}

#circle-right-middle {
    width: 45vw;
    height: 45vw;
    top: 17%;

    @include media-breakpoint-up-lg {
        width: 45vh;
        height: 45vh;
    }
}

#circle-right-bottom {
    width: 30vw;
    height: 30vw;
    top: unset;
    bottom: -2%;
    left: unset;
    right: 34%;

    @include media-breakpoint-up-lg {
        width: 30vh;
        height: 30vh;
    }
}