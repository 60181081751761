/**************************
*** Page: Under The Sun ***
**************************/

.bg-whitelonger-grey {
    background: linear-gradient($white-color 0%, $white-color 15%, $light-grey-color 15%, $light-grey-color 100%);
    
    @include media-breakpoint-up-lg {
        background: linear-gradient($white-color 0%, $white-color 35%, $light-grey-color 35%, $light-grey-color 100%);
    }
}

.work-title {
    font-size: 3rem;

    @include media-breakpoint-up-lg {
        font-size: 4.5rem;
    }
}

.work-header {
    // padding-top: 15rem;
    padding-top: 6rem;
    margin-bottom: 6rem;
}

#work-sun-header {

    #sun-mac {
        margin-top: -5%;
        width: 95%;
        z-index: 1;
        
        @include media-breakpoint-up-lg {
            width: 65%;
        }
    }

    .sakura {
        position: absolute;
        width: 30%;
    }

    #sakura-left {
        left: 5%;
        bottom: -5%;

        @include media-breakpoint-up-lg {
            left: 18%;
            bottom: -20%;
        }
    }

    #sakura-right {
        top: 20%;
        right: 2%;

        @include media-breakpoint-up-lg {
            top: 22%;
            right: 16%;
        }
    }
}

.bg-red-line {
    background: $red-color;
    height: 63%;
    position: absolute;
}

.bg-grey-canvas {
    background: #EFEBEF;
    height: 88%;
    width: 72%;
    position: absolute;
}

.work-content {
    margin: 4rem auto 0;

    @include media-breakpoint-up-lg {
        padding-left: 6rem;
    }

    @include media-breakpoint-up-xxl {
        padding-left: 12rem;
    }

    h4 {
        font-weight: 600;
    }
}

#sun-mac-reg {
    width: 90%;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

#butterfly-top {
    top: -20%;
    right: 10%;
    width: 13%;
}

#butterfly-bottom {
    bottom: 14%;
    left: -4%;
    width: 23%;
}

#counter {
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.counter-number {
    color: $mustard-color;
    font-size: 2.7rem;
    font-weight: bold;

    span {
        position: relative;
        bottom: 0.17em;
    }
}

.counter-title {
    font-size: 0.85rem;
}