/*********************
*** Page: Craft ***
*********************/

#work-craftvid-header {
    &.work-header {
        padding-top: 4rem;
        padding-bottom: 2.5rem;
    }
    
    .vidslider-container {
        padding: 0;
    }
}

.bg-brown {
    background:#EAE0CC;
    height: 80%;
    position: absolute;
    bottom: 0;
    // z-index: -1;
}

#work-craftvid-content {
    
    &.work-content {
        margin-bottom: 0;

        @include media-breakpoint-up-lg {
            padding-right: 6rem;
        }

        @include media-breakpoint-up-xxl {
            padding-right: 12rem;
        }
    }
}

.slick-prev {
    left: 2%;

    @include media-breakpoint-up-md {
        left: 9.3%;
    }
    
    &:before {
        content: "<";
        padding-right: 5px;
    }
}

.slick-next {
    right: 2%;

    @include media-breakpoint-up-md {
        right: 9.3%;
    }

    &:before {
        content: ">";
        padding-left: 5px;
    }
}

.slick-prev:before, .slick-next:before {
    font-size: 25px;
    font-weight: bold;
    color: #303030;
    opacity: 0.5;
}

.slick-prev, .slick-next {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
    z-index: 1;
}

.craftvid-slide {
    max-width: 100%;
    width: 100vw;
    transform: translateY(55%);
    opacity: 0.5;
    transition-duration: 0.3s;

    @include media-breakpoint-up-lg {
        width: 40vw;
    }

    &.slick-center {
        width: 100vw;
        transform: none;
        opacity: 1;
        transition-duration: 0.3s;
        transition-delay: 0.2s;

        @include media-breakpoint-up-lg {
            width: 40vw;
        }

        .craft-video {
            transition-duration: 0.3s;

            @include media-breakpoint-up-lg {
                width: 60vw;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .craft-text {
            display: block;
        }
    }
}

.craft-videos-slider {
    .craft-text {
        font-size: 2.2rem;
        -webkit-text-stroke-width: 1px;
        display: none;

        @include media-breakpoint-up-md {
            font-size: 3.5rem;
            -webkit-text-stroke-width: 2px;
        }
    }
}
