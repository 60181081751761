/* Responsive Breakpoints */


// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
$breakpoint-sm: 576px;

// Medium devices (tablets, 768px and up)
$breakpoint-md: 768px;

// Large devices (desktops, 992px and up)
$breakpoint-lg: 992px;

// Extra large devices (large desktops, 1400px and up)
$breakpoint-xl: 1400px;

// Extra large devices (large desktops, 1600px and up)
$breakpoint-xxl: 1600px;


// media breakpoint from small to bigger screen
@mixin media-breakpoint-up-sm {
    @media (min-width: $breakpoint-sm) { @content; }
}
@mixin media-breakpoint-up-md {
    @media (min-width: $breakpoint-md) { @content; }
}
@mixin media-breakpoint-up-lg {
    @media (min-width: $breakpoint-lg) { @content; }
}
@mixin media-breakpoint-up-xl {
    @media (min-width: $breakpoint-xl) { @content; }
}
@mixin media-breakpoint-up-xxl {
    @media (min-width: $breakpoint-xxl) { @content; }
}


// media breakpoint from big to smaller screen
@mixin media-breakpoint-down-xs {
    @media (max-width: $breakpoint-sm - 1) { @content; }
}
@mixin media-breakpoint-down-sm {
    @media (max-width: $breakpoint-md - 1) { @content; }
}
@mixin media-breakpoint-down-md {
    @media (max-width: $breakpoint-lg - 1) { @content; }
}
@mixin media-breakpoint-down-lg {
    @media (max-width: $breakpoint-xl - 1) { @content; }
}
@mixin media-breakpoint-down-xl {
    @media (max-width: $breakpoint-xxl - 1) { @content; }
}


// media breakpoint for specific device
@mixin media-breakpoint-only-xs {
    @media (max-width: $breakpoint-sm - 1) { @content; }
}
@mixin media-breakpoint-only-sm {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) { @content; }
}
@mixin media-breakpoint-only-md {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) { @content; }
}
@mixin media-breakpoint-only-lg {
    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1) { @content; }
}
@mixin media-breakpoint-only-xl {
    @media (min-width: $breakpoint-xl) { @content; }
}
@mixin media-breakpoint-only-xxl {
    @media (min-width: $breakpoint-xxl) { @content; }
}
