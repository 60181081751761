/*********************
*** Page: Services ***
*********************/

.row-title {
    padding: 4rem 1rem;

    @include media-breakpoint-up-xl {
        padding: 10rem 1rem;
    }
}

.highlighted-title {
    color: $teal-color;
}

.col-page-desc {
    padding: 0.5rem;

    @include media-breakpoint-up-md {
        padding: 1rem;
    }

    @include media-breakpoint-up-lg {
        padding: 1rem 3rem;
    }
}

.bg-white-grey {
    background: linear-gradient($white-color 0%, $white-color 5%, $light-grey-color 5%, $light-grey-color 100%);

    @include media-breakpoint-up-lg {
        background: linear-gradient($white-color 0%, $white-color 8%, $light-grey-color 8%, $light-grey-color 100%);
    }
}

.container-fluid.bg-white-grey {
    padding: 0;
}

.bg-teal-transparent {
    background: $teal-color;
    background: linear-gradient(to right,  $teal-color 0%, $teal-color 90%,transparent 90%,transparent 100%); /* W3C */
    height: 400px;
    position: absolute;
}

.row-service {
    text-align: center;
    align-items: center;
    padding: 3rem 0;

    @include media-breakpoint-down-lg {
        padding: 6rem 0;
    }
}

.service-title {
    margin-top: 2rem;
}

.service-content {
    padding: 1rem;

    @include media-breakpoint-up-md {
        padding: 1rem 3rem;
    }
}

.services-img-container {
    width: 100%;
    height: 450px;
}

.leftmost {
    right: unset;
    left: 0;
}

.rightmost {
    left: unset;
    right: 0;
}

.service-base-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.service-top-img {
    position: absolute;
    top: 48%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 59%;
}

#content-marketing-img {
    .service-base-img {
        top: 55%;
        left: 60%;
        width: 70%;

        @include media-breakpoint-down-sm {
            left: 50%;
        }
    }

    .service-top-img {
        top: 55%;
        left: 90%;
        width: 30%;

        @include media-breakpoint-down-sm {
            left: 80%;
        }
    }
}

#seo-img {
    .service-base-img {
        top: 65%;
        width: 70%;

        @include media-breakpoint-up-lg {
            width: 80%;
        }

        &.seo-base-img-top {
            width: 12%;
            top: 40%;
        }
    }

    .service-top-img {
        top: 71%;
        left: 82%;
        width: 30%;

        @include media-breakpoint-up-lg {
            top: 75%;
            left: 88%;
            width: 43%;
        }
    }
}

#socmed-marketing-img {
    .service-base-img {
        top: 40%;
        left: 35%;
        width: 100%;

        @include media-breakpoint-up-md {
            left: 45%;
        }

        &.socmed-base-img2 {
            top: 52%;
            left: 50%;
            width: 100%;

            @include media-breakpoint-up-md {
                left: 75%;
            }
        }
    }

    .service-top-img {
        width: 15%;
        left: 41%;

        &.socmed-top-img2 {
            width: 15%;
            left: 54%;
        }

        &.socmed-top-img3 {
            width: 15%;
            left: 67%;
        }

        &.socmed-top-img4 {
            width: 15%;
            left: 80%;
        }
    }
}

.square-big {
    width: 68%;
    height: 100%;
    position: absolute;
    right: 0;
}

.square-small {
    width: 72%;
    height: 80%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.bg-white {
    background-color: $white-color;
}

.bg-yellow {
    background-color: $yellow-color;
}

.bg-teal {
    background-color: $teal-color;
}

.bg-blue-theme {
    background-color: $secondary-color;
}

/*** Next Page Section ***/
.row-link {
    padding: 6rem 0 6rem 1rem;
    color: $white-color;
    font-size: 3rem;

    @include media-breakpoint-up-md {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @include media-breakpoint-up-lg {
        padding-left: 12rem;
        padding-right: 12rem;
    }

    @include media-breakpoint-up-xxl {
        padding-left: 18rem;
        padding-right: 18rem;
    }

    .next-title {
        @include media-breakpoint-down-md {
            line-height: 1.2;
        }
    }

    .line-between {
        &::after {
            content: '';
            position: relative;
            width: 100%;
            height: 1px;
            // margin: 0 1rem;
            background: $white-color;
            display: inline-flex;
            vertical-align: middle;

            @include media-breakpoint-up-lg {
                width: 100%;
            }
        }
    }

    .next-word {
        font-family: $primary-font-family;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
    }
}

/*** Contact Form Section ***/
.service-contact {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: $bg-two-colors-borders-longer;

    @include media-breakpoint-up-lg {
        background: $bg-two-colors-borders-desktop;
    }
}

#contact-section {
    .contact-content-wrapper {
        @include media-breakpoint-up-xl {
            margin-top: unset;
        }
    }

    .contact-content {
        padding: 2rem 0 2rem;
    }
}

.pointer {
    &:hover {
        cursor: pointer;
    }
}
