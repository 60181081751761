/***************
***Typography***
***************/

body {
    font-family: $primary-font-family;
    color: $dark-color;
    font-size: 1rem;

    @include media-breakpoint-up-xl {
        font-size: 1.1rem;
    }
}

h1{
    font-family: $secondary-font-family;
    font-weight: 500;
    letter-spacing: 0;
    font-size: 2.5rem;

    @include media-breakpoint-up-md {
        font-size: 3rem;
    }

    @include media-breakpoint-up-lg {
        font-size: 3.5rem;
    }
}

h2, h3 {
    letter-spacing: 1.5px;
}

h2 {
    font-family: $secondary-font-family;

    @include media-breakpoint-up-md {
        font-size: 3.5rem;
    }
}

h3 {
    @include media-breakpoint-up-md {
        font-size: 2rem;
    }
}

h5 {
    font-weight: 600;
}

a {
    font-family: $secondary-font-family;
    color: $primary-color;
    // font-weight: 700;
    transition: color 0.5s;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }
}

.ws-nowrap {
	white-space: nowrap;
}
