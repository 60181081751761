/**************************
*** Page: KUSARI ***
**************************/

.bg-lightblue-line {
    background: #D4EDF4;
    height: 63%;
    position: absolute;
}

.bg-light-shadow {
    background: #FFFFFF;
    border: 20px solid #f5f5f5;
    height: 82%;
    width: 70%;
    position: absolute;
    bottom: -2%;
    left: 10%;
}

// #kusari-desktop {
//     position: relative;
// }

#back-lines {
    top: 20%;
    right: 0;
    // z-index: 1;
    width: 40%;
}

#kusari-mobile {
    bottom: 0;
    right: 8%;
    z-index: 1;
    width: 20%;
}