/**************************
*** Page: Madam Loan ***
**************************/

#madamloan-video {
    border: 0.5rem solid #f9f9f9;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up-lg {
        border-width: 1rem;
    }
}

.bg-ivory-line {
    background: #FCF3EA;
    height: 63%;
    position: absolute;
}

.bg-light-shadow {
    background: #FFFFFF;
    border: 20px solid #f5f5f5;
    height: 82%;
    width: 70%;
    position: absolute;
    bottom: -2%;
    left: 10%;
}

#square-small {
    top: -20%;
    right: 32%;
    width: 6%;
}

#square-big {
    top: -8%;
    right: 18%;
    width: 11%;
}

#circle-dots {
    bottom: -28%;
    left: -9%;
    width: 40%;
}

#laptop-madamloan {
    position: relative;
    z-index: 1;
}
