/***********************************
*** Page: Get in Touch (Contact) ***
***********************************/

.contact-wrapper {
    min-height: 100vh;
    background: $bg-two-colors-borders-longer;
    position: relative;
    
    @include media-breakpoint-up-md {
        background: $bg-two-colors-borders-tablet;
    }
    
    @include media-breakpoint-up-lg {
        background: $bg-two-colors-borders-desktop;
    }
}

#contact-section {
    color: $white-color;

    @include media-breakpoint-up-md {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include media-breakpoint-up-xxl {
        padding-left: 14rem;
        padding-right: 14rem;
    }

    .contact-content {
        padding: 0 0 2rem;
    }

    .contact-content-wrapper {
        @include media-breakpoint-up-lg {
            align-items: center;
        }
        @include media-breakpoint-up-xl {
            margin-top: 2rem;
        }
    }    
    .contact-title {
        padding: 20px 0;

        @include media-breakpoint-up-md {
            padding-right: 3.5rem;
        }
    }

    .nav-left-col {
        display: block;

        @include media-breakpoint-up-md {
            margin: 2rem 2rem 7rem;
        }
        @include media-breakpoint-up-lg {
            margin: unset;
        }
    }
}

.contact-info {
    padding: 1rem;

    @include media-breakpoint-up-md {
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up-lg {
        padding: 2rem 3rem;
    }

    .newsletter-mail {
        font-family: $primary-font-family;
        font-size: inherit;
        color: $theme-dark-color;
        background: $white-color;
        border: 2px solid $white-color;
        border-radius: 20rem;
        padding: 0.45rem 0.5rem;
        transition-duration: 0.3s;
    
        &:hover {
            background-color: $mustard-color;
            color: $white-color;
        }
    }
}

.get-in-touch {
    .contact-content-wrapper {
        min-height: 70vh;
        margin-bottom: 4rem;
    }
}

form#newsletter {
    margin-top: 1rem;
}

form {
    width: 100%;

    @include media-breakpoint-up-md {
        width: 90%;
    }

    @include media-breakpoint-up-xl {
        width: 100%;
    }

    .form-control {
        background: none;
        border: none;
        border-bottom: 1px solid $white-color;
        border-radius: 0;
        color: $white-color;
        padding-left: 0;

        &:focus, &:hover {
            color: $white-color;
            background-color: transparent;
            border-color: $yellow-color;
            outline: 0;
            -webkit-box-shadow: 0 0.2rem 0 0 rgba(253, 199, 16, 0.5);
            box-shadow: 0 0.2rem 0 0 rgba(253, 199, 16, 0.5);
        }
    }

    input {
        &:focus {
            &::placeholder {
                color: transparent !important;
            }
        }

        &::placeholder {
            color: $white-color !important;
        }
    }

    textarea {
        &:focus {
            &::placeholder {
                color: transparent !important;
            }
        }

        &::placeholder {
            color: $white-color !important;
        }   
    }

    textarea#message {
        resize: none;
    }

    .btn {
        color: $white-color;
        align-items: flex-end;
        text-align: right;
        border-bottom: 1px solid $white-color;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;

        &:focus, &.focus {
            box-shadow: none;
        }
        
        &.submit-text {
            font-family: $secondary-font-family;
            letter-spacing: 1px;
            font-weight: 600;
            border-bottom-width: 2px;
            padding-bottom: 0;
            margin-top: 20px;
            transition: all 0.3s;

            &:hover {
                border-bottom-color: $yellow-color;
                color: $yellow-color;
            }
        }
    }
}

.form-check-label {
    margin-left: 1rem;
    font-size: 1rem;

    &::before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: $white-color;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        cursor: pointer;
        transition-duration: 0.3s;
    }

    &::after {
        content: '';
        transition-duration: 0.3s;
    }

    a {
        font-family: $primary-font-family;
        color: $white-color;
    }
}

.form-check-input {
    opacity: 0;

    &:checked + .form-check-label::before {
        background-color: $yellow-color;
        transition-duration: 0.3s;
    }
    &:checked + .form-check-label::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 2px;
        width: 3px;
        height: 3px;
        background-color: $theme-dark-color;
        box-shadow: 
            2px 0 0 $theme-dark-color,
            4px 0 0 $theme-dark-color,
            4px -3px 0 $theme-dark-color,
            4px -6px 0 $theme-dark-color,
            4px -9px 0 $theme-dark-color,
            4px -12px 0 $theme-dark-color;
        transform: rotate(45deg);
        transition-duration: 0.3s;
    }
}

.submit-arrow {
    margin-bottom: 2px;
    margin-left: 5px;
    font-size: large;
    font-weight: bold;
    padding: 0;
}

.not-seen {
    display: none;
}

@-webkit-keyframes autofill {
    0%, 100% {
        color: $dark-color;
        font-weight: bold;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}