/*********************
*** Page: Maserati ***
*********************/

#work-maserati-header {

    #maserati-car {
        width: 90%;
        margin-top: -5%;
        z-index: 1;
    }

    #maserati-edm {
        position: absolute;
        width: 12%;
        left: 15%;
        bottom: -11%;
        z-index: 2;
    }

}

.bg-two-greens {
    background: linear-gradient(125deg, #EAF2F0 0%, #EAF2F0 58%, #E1EEEB 58%, #E1EEEB 100%);
    width: 37%;
    height: 130%;
    position: absolute;
    // z-index: -1;
    right: 0;
    top: 40%;
}

#maserati-ipad {
    width: 80%;
}
