/*********************
*** Page: Double Time ***
*********************/

#work-doubletime-header {
    margin-bottom: 4rem;

    img {
        position: relative;
        top: -15%;
    }
    
    #doubletime-designs {
        position: absolute;
        top: 0;
        right: 0;
        
        @include media-breakpoint-up-lg {
            max-width: 65%;
        }
    }

}

.bg-babyblue-line {
    background: #D5EBFA;
    height: 100%;
    position: absolute;
}
