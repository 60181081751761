/***********************
*** Page: Free Scoop ***
***********************/

#work-baskin-header {

    &.work-header {
        padding-top: 2rem;
    }
}

#baskin-header-vid {
    border: 0.5rem solid white;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up-lg {
        border-width: 1rem;
    }
}

.bg-night {
    background: url("../../../img/work/baskin/br-header-bg.png");
    background-size: cover;
    background-position-y: bottom;
    height: 75%;
    position: absolute;
}

#fw-left-bg {
    width: 25%;
    top: -16%;
    left: -5%;
}

img#fw-center-bg {
    width: 26%;
    top: -31%;
    left: 40%;
}

#fw-right-sm {
    width: 17%;
    top: 2%;
    left: 84%;
}