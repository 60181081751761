/*********************
*** Page: Warmest As One  ***
*********************/

main.warmest-as-one #warmest-cny-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	margin: auto;
}

main.warmest-as-one .work-header .embed-responsive {
	border: 8px solid #A41D21;
}


main.warmest-as-one #warmest-phone-mockup {
	@include media-breakpoint-down-md {
		display: block;
		width: 60%;
		margin: auto;
		margin-top: 3rem;
	}

	@media only screen and (min-width: 1900px) {
		display: block;
		width: 80%;
		margin: auto;
	}
}
