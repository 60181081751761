footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: flex-end;
    padding: 1rem;

    @include media-breakpoint-up-md {
        padding: 0.2rem 1rem;
    }

    @include media-breakpoint-up-xl {
        padding: 1rem;
    }

    .copyright {
        color: $white-color;
        font-size: 0.53rem;
        // margin-top: 3rem;
        
        @include media-breakpoint-up-md {
            // margin: 5rem 2rem 0;
            font-size: 12px;
        }
        
        p {
            margin-bottom: 0;
            
            @include media-breakpoint-up-xl {
                margin-bottom: 0.5rem;
            }
        }
    
        a {
            color: $white-color;
            font-weight: normal;
            font-family: $primary-font-family;
        }
    }
}

.footer-disclaimer {
    color: $grey-color;
    font-size: 0.53rem;
    margin-bottom: 0;
    
    @include media-breakpoint-up-md {
        padding-left: 2rem;
        font-size: 15px;
    }

    .footer-logo {
        padding-bottom: 2px;
        height: 10px;
        width: auto;

        @include media-breakpoint-up-md {
            height: 17px;
            padding-bottom: 4px;
        }
    }
}