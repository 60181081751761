/*********************
*** Page: Work ***
*********************/

.works {
    .highlighted-title {
        color: $yellow-color;
    }
}

.work--section {
    @include media-breakpoint-up-md {
        padding-top: 12rem;
        padding-bottom: 8rem;
    }
}

.bg-yellow-transparent {
    background: $yellow-color;
    background: linear-gradient(to right,  $yellow-color 0%, $yellow-color 90%,transparent 90%,transparent 100%); /* W3C */
    height: 400px;
    position: absolute;
}

.row-work {
    flex-direction: row-reverse;

    @include media-breakpoint-up-xxl {
        margin-left: 10rem;
        margin-right: 3rem;
    }

    >div:nth-of-type(odd) {
        @include media-breakpoint-up-md {
            margin-top: -10rem;
            margin-bottom: 10rem;
        }
    }
}

.work-img {
    position: absolute;
    z-index: 1;
    top: 3%;
    // max-height: 34rem;
    max-height: 58vh;
    left: 50%;
    transform: translateX(-50%);
}

.work-card {
    margin: 2rem auto;
    padding: 0;
    box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.05);
    border-radius: 2%;
    border-color: rgba(0, 0, 0, 0.01);
    transition: all 0.5s ease;

    @include media-breakpoint-up-lg {
        margin: 5rem auto;
    }

    img.card-img-top {
        transition: all 0.5s ease;
    }

    &:hover {
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);

        img.card-img-top {
            transform: scale(0.9);
        }
    }
}

.work-img-base {
    height: 75vw;
    width: 100%;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
    position: relative;

    @include media-breakpoint-up-md {
        // height: 40vw;
        height: calc(100vw/2.7);
    }

    // @include media-breakpoint-up-lg {
    //     height: calc(100vw/3);
    // }

    @include media-breakpoint-up-xl {
        // height: 48vh;
        height: calc(100vw/3.5);
    }
}

img.work-img-base {
    height: auto;
}

.card-body {
    padding: 2.5rem 2rem 1.25rem;
}

.card-title {
    font-family: $tertiary-font-family;
    font-weight: 700;
    color: $dark-color;
}

.card-subtitle {
    font-family: $primary-font-family;
    font-size: 17px;
    font-weight: 400;
    padding: 20px inherit;
    color: $dark-color;
    opacity: 0.5;
}

.card-text {
    font-family: $primary-font-family;
    font-size: 17px;
    padding-top: 20px;
    color: $dark-color;
}

#card-top-undersun {
    background-image: url("../../img/work/under-sun/sun-bg.png");
}

#card-top-ewlabs {
    background-color: #FBF6EC;
    overflow: hidden;
    position: relative;
}

#card-top-maserati {
    background: linear-gradient(125deg, #EAF2F0 0%, #EAF2F0 65%, #E1EEEB 65%, #E1EEEB 100%);
}

#card-top-fsnite {
    background-image: url("../../img/work/baskin/featured-baskin-bg.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

#card-top-craftvid {
    background-color:#EAE0CC;
}

#card-top-ferrari {
    background-color:#EFEBEB;
}

#card-top-kusari {
    background-image: url("../../img/work/kusari/kusari-card-bg.jpg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;

}

#card-top-doubletime {
    background-color:#D5EBFA;
}

#sun-sunway {
    width: 100%;
    transition: all 0.5s ease;
}

#card-undersun:hover {
    #sun-sunway {
        transform: scale(1.05);
    }
}

$card-trasition: all 0.5s ease;
#card-top-vax {
	isolation: isolate;
	background-color: #135C66;
}

#vax-aspen {
	position: absolute;
	top: -3%;
	left: -3%;
	transform: scale(1);
	transition: $card-trasition;
}

#card-vax:hover #vax-aspen {
	transform: scale(1.05);
}

#vax-deco-1 {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: -1;
	max-width: 45%;
}

#vax-deco-2 {
	position: absolute;
	top: 10px;
	right: 10px;
	margin: auto;
	z-index: -1;
}

#momentum-maserati {
	position: absolute;
	top: 0;
	transition: $card-trasition;
}

#card-momentum:hover #momentum-maserati {
	transform: scale(.9);
}

#card-warmest:hover #warmest-sunway-phone {
	transform: scale(1.05);
}

#warmest-sunway-bg {
	position: absolute;
	width: 100%;
	border-top-left-radius: 2%;
	border-top-right-radius: 2%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#warmest-sunway-phone {
	position: absolute;
	left: 0;
	right: 0;
	top: -6%;
	margin: auto;
	width: 70%;
	transform: scale(1);
	transition: $card-trasition;
}

#img-ewlabs-phone-right {
    top: -9%;
    left: 75%;
    height: 70vw;
    transition: all 0.5s ease;

    @include media-breakpoint-up-md {
        height: 35vw;
    }

    @include media-breakpoint-up-lg {
        height: 45vh;
        left: 70%;
    }
}

#img-ewlabs-phone-left {
    top: 10%;
    left: 35%;
    height: 70vw;
    transition: all 0.5s ease;

    @include media-breakpoint-up-md {
        height: 35vw;
    }

    @include media-breakpoint-up-lg {
        height: 45vh;
    }
}

#card-ewlabs:hover {
    #img-ewlabs-phone-right {
        top: -6%;
    }

    #img-ewlabs-phone-left {
        top: 7%;
    }
}

.circle-1 {
    width: 50vw;
    height: 50vw;
    border-radius: 50%;
    background-color: rgba(252,89,89,0.3);
    position: absolute;
    top: 10%;
    left: 2%;

    @include media-breakpoint-up-md {
        width: 30vw;
        height: 30vw;
        top: 40%;
    }

    @include media-breakpoint-up-lg {
        width: 50vh;
        height: 50vh;
        top: 10%;
    }
}

.circle-2 {
    width: 35vw;
    height: 35vw;
    border-radius: 50%;
    background-color: rgba(252,89,89,0.3);
    position: absolute;
    top: -40%;
    right: -15%;

    @include media-breakpoint-up-md {
        width: 20vw;
    height: 20vw;
    top: -20%;
    }

    @include media-breakpoint-up-lg {
        width: 35vh;
        height: 35vh;
        top: -40%;
    }
}

#img-maserati-ipad {
    top: -3%;
    left: 42%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-lg {
        top: -6%;
    }
}

#img-maserati-phone {
    width: 30%;
    top: 40%;
    left: 82%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-lg {
        width: 27%;
        top: 24%;
    }
}

#card-maserati:hover {
    #img-maserati-ipad {
        left: 40%;
    }

    #img-maserati-phone {
        left: 85%;
    }
}

#img-fsn-fw-left {
    width: 35%;
    left: 10%;

    @include media-breakpoint-up-sm {
        width: 31%;
    }
}

#img-fsn-fw-right {
    width: 38%;
    top: 6%;
    left: 92%;

    @include media-breakpoint-up-lg {
        width: 29%;
        left: 90%;
    }
}

#img-baskin-cone {
    width: 33%;
    top: 10%;
    left: 85%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-lg {
        top: 0%;
        left: 77%;
    }
}

#img-freescoop-title {
    width: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;

    @include media-breakpoint-up-sm {
        width: 40%;
    }
}

#img-baskin-cup {
    top: 70%;
    left: 14%;
    width: 20%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-md {
        left: 22%;
    }
}

#card-fsnite:hover {
    #img-baskin-cone {
        width:30%;
    }

    #img-freescoop-title {
        width: 45%;
    }

    #img-baskin-cup {
        width: 17%;
    }
}

.craft-text {
    text-align: center;
    padding-top: 4%;
    font-size: 4rem;
    // -webkit-text-stroke: 0.01rem, #ffffff;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 3px;
    -webkit-text-fill-color: transparent;

    @include media-breakpoint-up-xxl {
        font-size: 6rem;
    }
}

#text-craft-vid {
    transition: all 0.5s ease;
}

#img-craft-vid {
    width: 48%;
    top: unset;
    bottom: 2%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-sm {
        bottom: 7%;
    }

    @include media-breakpoint-up-xxl {
        width: 60%;
    }
}

#card-craftvid:hover {
    #text-craft-vid {
        animation: craft-fill 0.7s infinite alternate;
    }

    #img-craft-vid {
        width: 43%;
    }
}

@keyframes craft-fill {
    from {
        -webkit-text-stroke-color: transparent;
    }

    to {
        -webkit-text-stroke-color: #ffffff;
    }
}

.ferrari-line {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    width: 100%;
}

.ferrari-red-line {
    background-color: #E10512;
    height: 2vw;
    position: relative;
}

.ferrari-darkred-line {
    background-color: #B2080F;
    height: 0.5vw;
    position: relative;
}

#img-ferrari-featuredlogo {
    width: 40%;
    top: 13%;
    left: 75%;
}

#img-ferrari-vid {
    width: 31%;
    left: 19%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-sm {
        top: -11%;
    }
}

#img-ferrari-ig {
    width: 31%;
    top: 28%;
    left: 40%;
    transition: all 0.5s ease;

    @include media-breakpoint-up-sm {
        top: 18%;
    }

    @include media-breakpoint-up-xxl {
        top: 8%;
    }
}

#card-ferrari:hover {
    #img-ferrari-vid {
        left: 16%;
    }

    #img-ferrari-ig {
        left: 43%;
    }
}

#clients.container-fluid {
    padding: 0;
    background: white;
}

.client-slide {
    margin: 0 1px;

    img {
        display: initial;
    }
}

.client {
    min-width: 100%;
    width: 13vw;
    height: 130px;
    background-color: #F5F0EA;
    margin: 1px;
    padding: 3vw;

    @include media-breakpoint-up-lg {
        padding: 3vh;
        height: 12vh;
    }

    // @include media-breakpoint-up-xl {
    //     height: 8vh;
    // }
}

.client-img {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

#hand-kusari {
    transform-origin: left;
    transition: all 0.5s ease;
}

#card-kusari:hover {
    #hand-kusari {
        transform: scale(1.05);
    }
}

#card-doubletime {
    overflow: hidden;

    img {
        transition: all 0.8s ease;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}
