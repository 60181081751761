html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

.wrapper {
    background: #202020;
    overflow: hidden;
}

.wrapper-view {
    background: white;
}

.p-footer {
    padding-bottom: 80px;
}

/* This only works with JavaScript, 
if it's not present, don't show loader */
.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.page-loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url("../../img/page-loader.gif") center no-repeat #fff;
}

/*****************
*** Page: Home ***
*****************/

.bg-two-colors {
    background: $primary-color;
    background: $bg-two-colors;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary-color', endColorstr='$secondary-color',GradientType=1 ); /* IE6-8 */

    @include media-breakpoint-up-lg {
        background: $bg-two-colors-desktop;
    }
}

.home-top-bar {
    // background: linear-gradient(to right,  $white-color 0%, $white-color 86%, $secondary-color 86%, $secondary-color 100%);
    position: fixed;
    width: 100%;
    z-index: 1;
}

section {
    position: relative;
}

.middle-content {
    // background: linear-gradient(to right,  $white-color 0%, $white-color 85%, $secondary-color 85%, $secondary-color 100%);
    background: $white-color;
    min-height: calc(100vh - 132px - 60px);     // 132px : is the client list slider's height, 60px : is the footer's height
    padding-top: 100px;
    
    @include media-breakpoint-up-md {
        min-height: calc(100vh - 132px - 100px);
    }
    
    @include media-breakpoint-up-lg {
        min-height: calc(100vh - 10vh - 2px - 100px);     // 12vh (+2px) : is the client list slider's height, 100px : is the footer's height
        width: 85%;
    }
    
    // @include media-breakpoint-up-xl {
    //     min-height: calc(100vh - 8vh - 2px - 100px);     // 12vh (+2px) : is the client list slider's height, 100px : is the footer's height
    //     // min-height: calc(100vh - 132px - 100px);
    //     // min-height: calc(100vh - 200px);
    // }
}

.col-page-title {
    // background-color: $white-color;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up-md {
        padding-left: 3rem;
    }

    @include media-breakpoint-up-lg {
        align-items: center;
    }

    @include media-breakpoint-up-xxl {
        padding: 2rem 5rem;
        // padding-left: 10rem;
    }
}

#home-page {
    @include media-breakpoint-only-xs {
        h1.page-title {
            font-size: 2rem;
        }
    }
}

h1.page-title {
    font-size: 3.3rem;
    color: $dark-color;

    @include media-breakpoint-up-md {
        font-size: 3.5rem;
    }

    @include media-breakpoint-up-lg {
        font-size: 3.5rem;
    }

    @include media-breakpoint-up-xl {
        font-size: 4rem;
    }

    @include media-breakpoint-up-xl {
        font-size: 5rem;
    }

    @include media-breakpoint-up-xxl {
        font-size: 7rem;
    }
}

.higlighted-text {
    color: $primary-color;
}

// .home-change-text {
//     &:after {
//         content: "insight-based solutions";
//         animation: textcontent 10s linear infinite;
//     }
// }

// @keyframes textcontent {
//     35% {
//         content: "result-focused executions";
//     }
//     70% {
//         content: "effectiveness-driven talents";
//     }
// }

.home-desc {
    // background-color: $white-color;
    padding: 0.5rem;
    display: flex;
    align-items: baseline;

    @include media-breakpoint-up-md {
        padding: 1rem 1rem 3rem 1rem;
    }
    
    @include media-breakpoint-up-lg {
        padding: 1rem 1rem 0 1rem;
        align-items: flex-end;
    }

    @include media-breakpoint-up-xl {
        // padding: 5rem 4rem;
        padding: 2rem 1rem 0;
    }
}

.link-underline {
    border-bottom: 2px solid $primary-color;
    transition: border-bottom 0.3s ease;
    font-weight: 600;

    &:hover {
        border-bottom: $secondary-color;
    }
}

#homeChangeText span {
    font-family: $secondary-font-family;
    font-weight: 500;
    position: relative;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
}