/*********************
*** Page: Vax To Win ***
*********************/

main.vax-to-win .masterhead-devices-wrapper {
	position: absolute;
	width: 65%;
	right: 5%;
	bottom: 0;
	margin: auto;
	transform: translateY(18%);

	@media only screen and (min-width: 1900px) {
		width: 40vw;
	}
}

main.vax-to-win .vax-to-win-col {
	isolation: isolate;
}

main.vax-to-win #vax-tablet-mockup {
	z-index: 1;
}

main.vax-to-win #vax-deco-1 {
	position: absolute;
	top: -10%;
	left: 3%;
	z-index: -1;
	margin: 0;

	@include media-breakpoint-down-md {
		width: 30%;
		top: -5%;
	}
}

main.vax-to-win #vax-deco-2 {
	position: absolute;
	bottom: 0;
	z-index: -1;
	right: 0;
	margin-bottom: -10%;
	margin-right: 20%;
	width: 35%;

	@include media-breakpoint-down-md{
		margin-bottom: 0;
	}
}

main.vax-to-win #work-vax-content {
    @include media-breakpoint-up-lg {
        padding-right: 6rem;
    }

    @include media-breakpoint-up-xxl {
        padding-right: 12rem;
    }
}
